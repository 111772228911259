.App {
  width: 100vw;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;

  nav .container {
    margin-top: 20px;
    
    .navbar-brand {
      font-size: 1.7rem;
    }

    .nav-item {
      a {
        color: #fff;
        font-size: 1.1rem;
        padding: 0;
        position: relative;
      }
      a:before,
      a:after {
        content: " ";
        position: absolute;
        z-index: -1;
        height: 15px;
        width: 20px;
        transition-duration: .7s;
        border: 0 solid #FFCA3A;
      }
      a:before {
        border-right-width: 3px;
        border-top-width: 3px;
        right: -12px;
        top: -8px;
      }
      a:after {
        border-bottom-width: 3px;
        border-left-width: 3px;
        left: -12px;
        bottom: -8px;
      }

      a:hover:before,
      a:hover:after {
          width: 180px;
          height: 55px;
      }

    }
  }

  #home {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    min-height: 100%;
    
    div {
      color: #fff;
  
      h1 {
        font-size: 6.5vw;
        animation-name: hero;
        animation-duration: 2s;
        transition-timing-function: ease-in-out;
      }
    }
    
    #castle-btn {
      font-size: 28px;
      color: #fff;
      text-decoration: none;
      position: absolute;
      bottom: 25px;
    }
  }
}

@media screen and (max-width: 576px) {
  .nav-item {
    margin-right: 10px;
  }
}

@keyframes hero {
  0%   {
    opacity: 0;
    transform: translateX(-100px);
    color: #818181;
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}